<template>
<div class="pa-6">
  <v-row v-if="user">
    <v-col cols="12" md="6">
      <v-card class="h-100" outlined flat>
        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="4">
              <div v-if="user.avatar && user.avatar != ''">
                <v-avatar rounded class="avatar-responsive mb-4">
                  <img :src="getAvatar(user.avatar)" :alt="user.name" />
                </v-avatar>
                <v-btn v-if="isAdmin" small outlined :disabled="loadingAvatar" :loading="loadingAvatar" @click="removeAvatar" style="width:100px">
                  {{ $t("profile.informations.delete") }}
                </v-btn>
              </div>
              <div v-else>
                <div class="avatar rounded mb-4"></div>
                <v-btn v-if="isAdmin" color="primary" outlined :disabled="loadingAvatar" :loading="loadingAvatar" @click="$refs.uploader.click()" small style="width:100px">
                  {{ $t("profile.informations.add") }}
                </v-btn>
                <input ref="uploader" class="d-none" type="file" accept="image/*" @change="addAvatar" />
              </div>
            </v-col>
            <v-col cols="8">
              <label> {{ $t("profile.informations.id") }} </label>
              <v-text-field
                :value="user.displayId"
                outlined
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card class="h-100" outlined flat>
        <v-card-text class="pa-4">
          <v-form ref="updateProfileForm" v-model="valid" :disabled="submitLoading">
            <v-row>
              <v-col cols="6">
                <h3 class="primary--text mb-2">
                  {{ $t("profile.informations.contact") }}
                </h3>
                <label> {{ $t("profile.informations.name") }} </label>
                <v-text-field
                  v-model="user.name"
                  outlined
                  dense
                  single-line
                  :rules="required"
                  :disabled="!isCustomerServiceProfile && !isAdmin"
                ></v-text-field>
                <label> {{ $t("profile.informations.surname") }} </label>
                <v-text-field
                  v-model="user.surname"
                  outlined
                  dense
                  :rules="required"
                  :disabled="!isCustomerServiceProfile && !isAdmin"
                ></v-text-field
                ><label> {{ $t("profile.informations.phone") }} </label>
                <v-text-field
                  v-model="user.phone"
                  outlined
                  dense
                  :rules="[...required, maxCharInPhoneNumber]"
                  :disabled="!isCustomerServiceProfile && !isAdmin"
                ></v-text-field>
                <label> {{ $t("profile.informations.email") }} </label>
                <v-text-field
                  :value="user.email"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <h3 class="primary--text mb-2">
                  {{ $t("profile.informations.address") }}
                </h3>
                <label> {{ $t("profile.informations.street") }} </label>
                <v-text-field
                  v-model="user.street"
                  outlined
                  dense
                  single-line
                  :rules="required"
                  :disabled="!isCustomerServiceProfile" />
                <label> {{ $t("profile.informations.zipCode") }} </label>
                <v-text-field
                  v-model="user.zipCode"
                  outlined
                  dense
                  single-line
                  :rules="[...required, maxCharInZipCode]"
                  :disabled="!isCustomerServiceProfile" />
                <label> {{ $t("profile.informations.city") }} </label>
                <v-text-field
                  v-model="user.city"
                  outlined
                  dense
                  single-line
                  :rules="required"
                  :disabled="!isCustomerServiceProfile" />
                <div v-if="isCustomerServiceProfile">
                  <label> {{ $t('userProfile.informations.role')}}</label>
                  <v-select
                    outlined
                    v-model="user.role"
                    dense
                    single-line
                    :items='roles'
                    item-value='id'
                    item-text='description' />
                </div>
              </v-col>
            </v-row>
            <div v-if="isCustomerServiceProfile || isAdmin" class="d-flex">
              <v-btn outlined class="ml-auto" color="primary" :disabled="submitLoading" :loading="submitLoading" @click="profileSubmit">
                {{ $t("profile.informations.update") }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<script>
import Service from '@/services'
import { fileUrl, maxCharInPhoneNumber, maxCharInZipCode } from '@/utils/helpers'

export default {
  name: 'Informations',
  props: {
    isCustomerServiceProfile: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      loadingAvatar: false,
      user: null,
      lang: [],
      languages: [],
      valid: true,
      files: [],
      submitLoading: false,
      required: [(v) => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')],
      maxCharInPhoneNumber,
      maxCharInZipCode
    }
  },
  computed: {
    roles () {
      return this.$store.getters.enums.customerServiceRoleTypes
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    userId () {
      return this.$route.params.userId
    }
  },
  async mounted () {
    await this.getUser()
  },
  methods: {
    initials (name, surname) {
      return name.slice(0, 1) + surname.slice(0, 1)
    },
    getAvatar (url) {
      return fileUrl(url)
    },
    async getUser () {
      if (this.isCustomerServiceProfile) {
        const response = await Service.get('UserManagement/GetUser/' + this.userId)
        this.user = response.data
      } else {
        const { data } = await Service.get(`User/GetCompanyUserProfile?userId=${this.userId}`)
        this.user = data
      }
    },
    async profileSubmit () {
      this.$refs.updateProfileForm.validate()
      if (!this.valid) {
        return
      }
      this.submitLoading = true
      try {
        if (this.$route.name === 'UserProfile') {
          await Service.put('/CompanyUserManagement/Update', {
            userId: this.user.id,
            name: this.user.name,
            surname: this.user.surname,
            phone: this.user.phone
          })
        } else {
          await Service.put('/UserManagement/UpdateUser', {
            userId: this.user.id,
            name: this.user.name,
            surname: this.user.surname,
            phone: this.user.phone,
            city: this.user.city,
            street: this.user.street,
            zipCode: this.user.zipCode,
            country: this.user.country,
            role: this.user.role
          })
        }
        this.$refs.updateProfileForm.resetValidation()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('userProfile.informations.updateChange')
        )
      } catch (e) {
      } finally {
        this.submitLoading = false
      }
    },
    async addAvatar (e) {
      this.loadingAvatar = true
      const files = Array.from(e.target.files)
      const file = new FormData()
      file.append('userId', this.user.id)
      file.append('avatar', files[0], files[0].name)

      try {
        await Service.post('/CompanyUserManagement/AddAvatar', file)
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('profile.informations.avatarChange')
        )
        await this.getUser()
      } catch (error) {}

      this.loadingAvatar = false
    },
    async removeAvatar () {
      this.loadingAvatar = true
      try {
        await Service.delete('/CompanyUserManagement/RemoveAvatar', {
          userId: this.user.id
        })
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('profile.informations.avatarRemove')
        )

        await this.getUser()
      } catch (error) {}
      this.loadingAvatar = false
    }
  }
}
</script>
<style scoped>
.avatar {
  width: 100px;
  height: 100px;
  border: solid thin  var(--v-primary-base);
  background-color: var(--v-background-base);
}
</style>
